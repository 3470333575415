.body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  overflow-x: hidden;
  
}

.quem-somos {
  display: flex;
  flex-direction: row;
}

.texto {
  align-items: center;
  width: 600px;

  color: #f1f1f1;
}

.body p {
  text-align: justify;
  margin-bottom: 1rem;
  margin-right: 1rem;
  margin-left: 1rem;
}

.mapa {
  margin-top: 18px;
  margin-bottom: 30px;
}

.image-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.images {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image-wrap span {
  position: absolute;
  margin-top: 150px;
  background-color: rgba(3, 3, 3, 0.514);
  padding: 6px;
  border-radius: 5px;
  color: #f1f1f1;
  font-weight: bold;
  font-size: 14px;
}

.images img {
  margin-bottom: 18px;
}
.text-on-image {
  position: absolute;
  right: 60%;
  left: 18%;
  bottom: 15%;
}
@media (max-width: 980px) {
  .quem-somos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .images img {
    width: 345px;
    height: 210px;
  }
  .mapa {
    margin-top: 0;
  }
  .mapa iframe {
    width: 345px;
    height: 210px;
  }
}

@media (max-width: 610px) {
  .texto {
    width: 500px;
  }
}

@media (max-width: 610px) {
  .texto {
    width: 500px;
  }
}

@media (max-width: 510px) {
  .texto {
    width: 390px;
  }
}
