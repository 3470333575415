.sidebar {
    width: 200px;
    margin: 0;
    padding: 0;
    background-color: #181c2e;
    position: fixed;
    height: 100%;
    overflow: auto;
}

.sidebar div{
  background: url('../../assets/cover.jpg');
  background-color: #181c2e;
  background-position: center;
  background-repeat: no-repeat; 
  background-size: cover;
  height: 150px;
}

.sidebar div{
padding-top: 30px;

}

.sidebar div img{
    width: 90px;
    height: 90px;
    display: block;
    margin: auto;
    border-radius: 50%;
    object-fit: cover;
    filter: drop-shadow(2px 3px 6px #121212);
    -webkit-filter: drop-shadow(2px 3px 6px #121212);
}

.sidebar a{
    display: block;
    padding: 16px;
    display: flex;
    text-decoration: none;
    color: rgba(255, 255,255, 0.7);
    flex-direction: row;
    align-items: center;
    transition: ease-in-out 0.2s;
}

.sidebar a svg{
    margin-right: 0.5rem;
}

.sidebar a:hover{
    background-color: #121212;
    color: #FFF
}

.content{
    margin-left: 200px;
    padding: 1px 16px;
}

@media screen and (max-width: 700px){
    .sidebar{
        width: 100%;
        height: auto;
        position: relative;
    }
    div.content{
        margin-left: 0;
    }
    .sidebar a{
        float: left;
    }
    .sidebar div{
        display: none;
    }
}

@media screen and (max-width: 400px ){
    .sidebar a{
        text-align: center;
        float:none
    }
    .sidebar a svg{
        display: none;
    }
}

