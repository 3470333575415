.main-container {
    position: relative;
    min-height: 100vh;
   
  
    min-height: 100vh;
    background: rgb(8, 16, 51);
    background: -moz-linear-gradient(
      180deg,
      rgba(8, 16, 51, 1) 0%,
      rgba(10, 19, 61, 1) 68%
    );
    background: -webkit-linear-gradient(
      180deg,
      rgba(8, 16, 51, 1) 0%,
      rgba(10, 19, 61, 1) 68%
    );
    background: linear-gradient(
      180deg,
      rgba(8, 16, 51, 1) 0%,
      rgba(10, 19, 61, 1) 68%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#081033",endColorstr="#0a133d",GradientType=1);
   
  }


.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 700px;
  margin-top: 10px;
  margin-bottom: 30px;
  
}

.titulo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 60px 0 30px 0;
  color: #f1f1f1;
}

.titulo h2 {
  font-size: 18px;
}

@media screen and (max-width: 960px) {
  .video-container {
    height: 90%;
    margin-top: 10px;
  }

}

