.video {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: auto;
  min-height: 225px;
  
  
}


@media  (max-width: 960px){
 .video {
  height: auto;
 }
  }
  