.container-center {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #121212;
  padding: 0 12px;
}

.login {
  background-color: #eaeaec;
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.login-area {
  background-color: #181c2e;
  width: 100%;
  display: flex;
  justify-content: center;
}

.login-area img {
  width: 170px;
  height: 130px;
  padding: 20px;
}

form{
    margin-top: 1.5rem ;
    width: 90%;
    display: flex;
    flex-direction: column;
}

form h1{
    text-align: center;
    margin-bottom: 1rem;
    color: #181c2e;
}

.login input {
    margin-bottom: 1rem;
    height: 35px;
    border-radius: 4px;
    padding: 10px;
    font-size: 15px;
    background-color: #FFF;
    
}

form button {
    height: 40px;
    width: 100%px;
    border: 0;
    border-radius: 4px;
    background-color: #181c2e;
    color: #fff;
    font-size: 1.3rem;
    text-align: center;
    margin-bottom: 8px;
    
}

form button:hover{
    background-color: #1b1f31;
}

.signin {
  margin-top: 18px;
  margin-bottom: 8px;
}

.login a {
    margin: 1.5rem 0;
    color: #000;
    cursor: pointer;
   
}

.login p{
  margin-bottom: 1rem;
  font-size: 12px;
}