
.main-title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 20px 0 20px;
}

.content-container {
  max-width: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 0 auto; 
}


@media (max-width: 560px) {



  .page-title h1 {
    font-size: 1.5rem;
    text-align: center;
  }

  .page-title h2 {
    font-size: 13px;
  }
}