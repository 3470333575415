.main-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.body {
  display: flex;
  justify-content: center;
}
.contact-area form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0;
}

.contact-area {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 450px;
  height: 450px;
  background-color: #ffffff;
  border-radius: 3px;
  margin-bottom: 30px;
  -webkit-box-shadow: 0px 0px 16px 1px rgba(0,0,0,0.81);
-moz-box-shadow: 0px 0px 16px 1px rgba(0,0,0,0.81);
box-shadow: 0px 0px 16px 1px rgba(0,0,0,0.81);
}
h2 {
  margin-bottom: 1rem;
  color: #0f1a4d;
}
.contact-area input {
  margin-bottom: 10px;
  height: 40px;
  width: 280px;
  padding-left: 6px;
  border-radius: 3px;
  border: 1px solid #cfcbcb;
}

.contact-area input[type="text"]:focus {
  outline: 2px solid #0f1a4d;
}
.contact-area textarea {
  margin-bottom: 10px;
  height: 100px;
  width: 280px;
  border-radius: 3px;
  padding-left: 6px;
  padding-top: 6px;
  font-family: "Roboto", sans-serif;
  border: 1px solid #cfcbcb;
  resize: none;
}
.contact-area textarea[type="text"]:focus {
  outline: 2px solid #0f1a4d;
}

.contact-area button {
  width: 250px;
  background-color: #0f1a4d;
}

@media (max-width: 960px) {
  .contact-area {
    width: 350px;
  }
}

@media (max-width: 400px) {
  .contact-area {
    width: 330px;
  }
}