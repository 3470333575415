.footer {
  margin-top: auto;
}

.footer-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
  background-color: #060a1a;
}

.footer-links {
  width: 700px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.programas-footer ul li {
  color: #ccc6c6;
  margin: 3px;
  
}
.programas-footer ul li:hover {
  color: #f1f1f1;
}

.social-media span {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ccc6c6;
}

.social-icon {
  margin: 5px;
}

.copyright {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 28px;
  color: #ccc6c6;
  font-size: 12px;
}

.copyright a:visited{
  color: #ccc6c6;
}

@media (max-width: 720px) {
  .footer-links {
    width: 500px;
  }
}

@media (max-width: 510px) {
  .logo img {
    width: 90px;
  }

  .canal img {
    width: 60px;
  }

  .footer-links {
    width: 340px;
  }

  .social-media {
    display: none;
  }
}
