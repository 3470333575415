.programas-container {
  position: relative;
  min-height: 100vh;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Ensure the spinner takes up the full viewport height */
  background-color: rgba(8, 16, 51, 1); /* Match the background color */
}
.programas-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
  background-color: rgba(0, 0, 0, 0.747);

  box-shadow: 0px 1px 100px 100px rgba(0, 0, 0, 0.85);
  -webkit-box-shadow: 40px 1px 170px 100px rgba(0, 0, 0, 0.85);
  -moz-box-shadow: 40px 1px 170px 100px rgba(0, 0, 0, 0.85);
}
.description {
  display: flex;
}
.programas-wrap {
  display: flex;
  justify-content: center;
  width: 90%;
}

.programas-title {
  display: flex;
  flex-direction: column;
  color: #f1f1f1;
  width: 600px;
  margin: 40px;
}

.programas-title h1 {
  
  margin-bottom: 26px;
}
.programas-title h4 {
  margin-bottom: 18px;
  font-size: 1rem;
}

.programas-title span {
  font-style: italic;
}

.meio{
  display: flex;
  justify-content: center;
  
}

.programas-title a {
  width: 150px;
  padding: 10px;
  margin-top: 10px;
  border-radius: 3px;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  text-align: center;
  font-weight: 700;
  background-color: #03459c;
  color: #f1f1f1;
}

.programas-title button:hover {
  background-color: #0755bb;
  color: #f1f1f1;
}

.cover-programa {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cover-programa img {
  width: 400px;
  height: 400px;
  object-fit: cover;
  border-radius: 3px;
  margin-right: 30px;
}

.listagem {
  display: flex;
  justify-content: center;
  margin-left: 30px;
  margin-right: 30px;
}
.listar-programas {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  column-gap: 5px;
  margin-bottom: 30px;
}

.card-container {
  background-color: transparent;
  box-shadow: 0px 0px 0px -5px #0063e5;
  transition: 0.2s;
  animation: ease-in-out;
  margin-bottom: 30px;
}

.card-container button {
  width: 100%;
  padding: 2px;
  border-radius: 3px;
  border-style: none;
}

.card-container:hover {
  transform: scale(1.2);
  box-shadow: 0px 0px 10px 5px #03459c;
}

.image-container {
  width: 100%;
}
.image-container img {
  width: 100%;
  border-radius: 3px;
}

@media (max-width:  960px){
  .listar-programas {
    grid-template-columns: repeat(5, 1fr);
  }

  .cover-programa img{
    width: 300px;
    height: 300px;
  }

}

@media (max-width: 700px){
  .cover-programa img {
    width: 200px;
    height: 200px;
  }
}

@media (max-width: 550px){
  .cover-programa{
    align-items: normal;
  }
  .cover-programa img {
    width: 130px;
    height: 130px;
    margin-right: 0;
  }
  .programas-title{
    margin: 10px;
  }
  .programas-title h1{
    font-size: 33px;
    margin-bottom: 10px;
  }
  .programas-title h4{
    font-size: 14px;
   
  }

  .programas-title span{
    font-size: 12px;
  }

  .programas-title a {
    width: 115px;
    padding: 10px;
    margin-top: 10px;
 
    font-size: 11px;
   
  }
}

@media (max-width: 260px){
  .cover-programa img{
    display: none;
  }
}