.erro {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #f1f1f1;
}

.erro h1{
    font-size: 40px;
}

.erro h2{
    font-size: 20px;
}

