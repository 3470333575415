.container span {
    margin-bottom: 1rem;
    font-size: 14px;
}

.container button {
    width: 160px;
    font-size: 16px;
}
.cover img {
    border-radius: 0;
}

.upload-files {
    width: 400px;
    display: flex;
    flex-direction: column;
  
    
}

.upload-files img{
    border-radius: 0;
    margin-bottom: 0;
}

.delete-btn{
    background-color: rgb(153, 2, 2);
    margin-top: 5px;
}

.delete-btn:hover{
    background-color: rgb(206, 2, 2);
    
}