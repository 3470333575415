.adicionar-programas{
    float: right;
    margin-bottom: 1.5em;
    background-color: #83bf02;
    padding: 0.5em;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    font-weight: 600;
    color: #FFF;
}

.adicionar-programas svg{
    margin-right: 5px;
}

.dashboard{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

table{
    border: 1px solid #ccc;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
}

table caption{
    font-size: 1.5em;
    margin: 0.5em 0 0.75em;
}

table tr{
    background-color: #f8f8f8;
    border: 1px solid #DDD;
    padding: 0.35em;
}

table th, table td{
    padding: 0.62em;
    text-align: center;
}

table th{
    font-size: 0.85em;
    letter-spacing: 0.1em;
    text-transform: uppercase;
}

table td .action {
    border: 0;
    padding: 4px;
    border-radius: 4px;
    display: inline-block;
    margin-right: 3px;
}

table td .action svg{
    vertical-align: middle;
}

table td .badge{
    padding: 3px;
    border-radius: 3px;
    color: #FFF;
}