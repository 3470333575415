.container-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("../../assets/igreja.jpg");
  background-position: center;
  background-size: cover;
  width: 80%;
  max-width: 1200px;
  min-width: 200px;
  height: 400px;
  border-radius: 5px;
  margin-bottom: 30px;
}

.container-textos {
  text-align: center;
  background-color: rgba(0, 0, 0, 0.849);
  border-radius: 3px;
  padding: 3%;
  margin: 5px;
}

.container-textos p {
  color: #f1f1f1;
  text-align: center;
}

@media (max-width: 365px) {
  .container-textos {
    width: 200px;
  }
  .container-textos h1 {
    font-size: 16px;
  }
  .container-textos p {
    font-size: 13px;
  }
}
