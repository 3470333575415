@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  
}

body {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  background-color: #efefef;
}

h1 {
  color: #f1f1f1;
}

h2 {
  color: #f1f1f1;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

button {
  cursor: pointer;
  border: none;
}

input {
  border: none;
  font-size: 16px;
}

textarea {
  font-size: 16px;
}

h4{
  font-weight: 200;
}