.playlist{
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #f1f1f1
}

.programas-content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.playlist svg{
    stroke: none;
}